<template>
  <BaseElSelect
    v-model="syncModel"
    v-bind="$attrs"
    filterable
    remote
    clearable
    reserve-keyword
    :placeholder="placeholder || '請輸入會員名稱或手機'"
    no-match-text="無匹配項目"
    no-data-text="暫無數據"
    :remote-method="remoteSearch"
    :loading="loading"
    value-key="id"
    @change="onChange"
    @clear="getMember(), $emit('clear')"
  >
    <i slot="prefix" class="el-input__icon el-icon-search" />
    <BaseElSelectOption
      v-for="item in membersList"
      :key="item.id"
      :label="item.UserInfo.phone ? `${item.UserInfo.name} (${item.UserInfo.phone})` : item.UserInfo.name"
      :value="item"
    >
      {{ item.UserInfo.phone ? `${item.UserInfo.name} (${item.UserInfo.phone})` : item.UserInfo.name }}
    </BaseElSelectOption>
  </BaseElSelect>
</template>
<script>
import { GetMembers } from '@/api/member'
import { mapGetters } from 'vuex'
import { get, find } from 'lodash'

export default {
  name: 'MemberSearch',
  props: ['model', 'type', 'placeholder'],

  computed: {
    ...mapGetters(['shop']),
    syncModel: {
      get () {
        return this.model
      },

      set (data) {
        this.$emit('update:model', data)
      },
    },
  },
  // :value="{name:item.UserInfo.name, phone:item.UserInfo.phone}"

  data: () => ({
    loading: false,
    membersList: [],
  }),

  async mounted () {
    await this.getMember()
    if (get(this.model, 'id') && !find(this.membersList, ['id', this.model.id])) {
      this.membersList.push(this.model)
    }
  },

  methods: {
    async remoteSearch (query) {
      query = query.trim()
      if (query === '') this.membersList = []

      try {
        this.loading = true
        setTimeout(async () => {
          await this.getMember(query)
          this.loading = false
        }, 200)
      } catch (error) {
        console.log(error)
      }
    },

    onChange (member) {
      this.$emit('change', member)
    },

    async getMember (query = null) {
      try {
        const res = await GetMembers({
          shopId: this.shop,
          name: query || undefined,
          phone: query || undefined,
          limit: 50,
          existPhone: false,
        })
        this.membersList = res
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style scoped lang="postcss">
/* ::v-deep .el-icon-::before {
  content: '\e778';
} */

::v-deep.el-select .el-input .el-select__caret {
  @apply transform rotate-0;
}
</style>
